<template>
    <div class="my-2 mx-2 w-100">
        <vs-card-group>
            <vs-card v-for="(item, index) in itemsCurrent" :key="index">
                <template #title>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h3 class="mx-80">{{ item.name }}</h3>
                        <vs-button
                            v-if="!disabled"
                            danger
                            :disabled="isLoadingComponent"
                            icon
                            @click="deleteBrand(index)"
                        >
                            🗑️
                        </vs-button>
                    </div>
                </template>
                <template #text>
                    <p>{{ item.name }} #{{ item.slug }}</p>
                </template>
            </vs-card>
        </vs-card-group>
        <div v-if="enabled" class="row">
            <div class="col-10 offset-1 d-flex justify-content-center">
                <slot name="action"></slot>
            </div>
            <div class="col-1">
                <vs-button
                    :disabled="isLoadingComponent"
                    circle
                    icon
                    @click="openNewBrand"
                >
                    ➕
                </vs-button>
            </div>
        </div>
        <div>
            <vs-dialog
                v-model="isOpenSelector"
                scroll
                overflow-hidden
                auto-width
                :loading="isLoadingComponent"
            >
                <template #header>
                    <div class="d-flex flex-column">
                        <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                            <h3 class="text-center">Seleccione una marca 🔖</h3>
                            <vs-button
                                circle
                                icon
                                floating
                                @click="loadBrands(true)"
                            >
                                <reload-icon />
                            </vs-button>
                            <vs-button
                                :disabled="!selectedItems.length"
                                icon
                                circle
                                @click="pushNewBrand"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </template>
                <div class="con-content">
                    <div
                        v-if="items.length"
                        class="d-flex flex-column justify-content-center align-items-center"
                    >
                        <button
                            v-for="(item, index) in items"
                            :key="index"
                            class="d-flex flex-column btn btn-primary w-75 w-min-30 my-2 text-left"
                            :class="
                                selectedItems.includes(item.slug)
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectionBrand(item.slug)"
                        >
                            <h3>{{ item.name }}</h3>
                            <p>{{ item.shortDescription }}</p>
                        </button>
                    </div>
                    <div v-else>
                        <span class="font-weight-bold text-md">
                            Nuestro sistema no encuentra resultados 😢
                        </span>
                    </div>
                    <div class="row d-flex justify-content-center mt-4">
                        <vs-button
                            v-if="pagesNumber > currentPage"
                            @click="loadMoreBrands"
                        >
                            Cargar más
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
    name: "BrandsCurrentManage",
    components: { ReloadIcon },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => [],
            type: Array,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        isOpenSelector: false,
        selectedItems: [],
        items: [],
        isLoadingComponent: false,
        currentPage: 1,
        pagesNumber: 0
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        ...mapActions("brands", ["listBrandsPaginated"]),
        ...mapActions("products", ["updateProductsBrands"]),
        async openNewBrand() {
            this.isOpenSelector = true;
            await this.loadBrands();
        },
        async loadMoreBrands() {
            this.currentPage = this.currentPage + 1;
            await this.loadBrands();
        },
        async loadBrands(complete = false) {
            this.isLoadingComponent = true;
            try {
                const { items, pagination } = await this.listBrandsPaginated({
                    page: this.currentPage,
                    complete
                });
                this.items = items;
                if (pagination) {
                    this.pagesNumber = pagination.pagesNumber;
                }
            } catch (error) {
                this.isOpenSelector = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingComponent = false;
            }
        },
        selectionBrand(slug) {
            this.selectedItems.push(slug);
        },
        pushNewBrand() {
            let brandsAux = this.items.filter((i) =>
                this.selectedItems.includes(i.slug)
            );
            if (brandsAux.length) {
                const brandsResult = JSON.parse(
                    JSON.stringify(this.itemsCurrent)
                );

                brandsAux = [...brandsResult, ...brandsAux];

                brandsAux = brandsAux.filter(
                    (value, index, self) =>
                        self.findIndex((item) => item.slug === value.slug) ===
                        index
                );
                this.isOpenSelector = false;
                this.$emit("change", brandsAux);
            }
        },
        deleteBrand(fromIndex) {
            let brandsResult = [...this.itemsCurrent];
            brandsResult.splice(fromIndex, 1);
            this.$emit("change", brandsResult);
        },
        setInput(value) {
            this.selectedItems = value.map((item) => item.slug);
        }
    }
};
</script>

<style scoped>
.vs-card__group .vs-card__group-cards .vs-card-content {
    min-width: 15rem;
    margin-left: 20px;
}
.vs-navbar-content {
    z-index: 1000 !important;
}
</style>
